const icons = {
  "head-arrow": {
    viewBox: "0 0 14 22",
    content:
      '<path d="M2.25 1.5L11.75 11L2.25 20.5" stroke="currentColor" stroke-width="2px" stroke-linecap="round" stroke-linejoin="round"/>',
  },
  "medium-arrow-right": {
    viewBox: "0 0 14 22",
    content:
      '<path d="M2.25 1.5L11.75 11L2.25 20.5" stroke="currentColor" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>',
  },
  "medium-arrow-left": {
    viewBox: "0 0 14 22",
    content:
      '<path d="M11.75 1.5L2.25 11L11.75 20.5" stroke="currentColor" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>',
  },
  more: {
    viewBox: "0 0 448 512",
    content:
      '<path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"/>',
  },
  "angle-right": {
    viewBox: "0 0 320 512",
    content:
      '<path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/>',
  },
  "chevron-right": {
    viewBox: "0 0 15 30",
    content:
      '<path fill="currentColor" d="M1.81787,30A1.81814,1.81814,0,0,1,.43211,27.00461L10.60861,15,.43211,2.99533A1.81894,1.81894,0,1,1,3.20719.64308L15.37706,15,3.20719,29.35686A1.8078,1.8078,0,0,1,1.81787,30Z"/>',
  },
  "chevron-down": {
    viewBox: "0 0 50 50",
    content:
      '<path fill="currentColor" d="M25,39.29c-.95,0-1.86-.37-2.53-1.05L1.05,16.81c-1.4-1.4-1.4-3.66,0-5.05s3.66-1.39,5.05,0l18.9,18.9,18.9-18.9c1.39-1.39,3.66-1.39,5.05,0,1.4,1.4,1.4,3.66,0,5.05l-21.43,21.43c-.67.67-1.58,1.05-2.53,1.05Z"/>',
  },
  "angle-down": {
    viewBox: "0 0 448 512",
    content:
      '<path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"/>',
  },
  "angle-left": {
    viewBox: "0 0 320 512",
    content:
      '<path fill="currentColor" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"/>',
  },
  "arrow-left": {
    viewBox: "0 0 448 512",
    content:
      '<path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"/>',
  },
  "arrow-right": {
    viewBox: "0 0 448 512",
    content:
      '<path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"/>',
  },
  "arrow-right-alt": {
    viewBox: "0 0 50 50",
    content:
      '<path fill="currentColor" d="M21.6818,0,19.24346,2.56292l19.62278,20.6252H4.53332v3.62376H38.86624L19.24346,47.43725,21.6818,50,45.46668,25.00012Z" />',
  },
  "arrow-left-alt": {
    viewBox: "0 0 50 50",
    content:
      '<path fill="currentColor" d="M4.53332,25.00012,28.3182,50l2.43834-2.56275L11.13376,26.81188H45.46668V23.18812H11.13376L30.75654,2.56292,28.3182,0Z" />',
  },
  home: {
    viewBox: "0 0 30 30",
    content:
      '<path fill="currentColor" d="M29.49487,10.82893,16.49948,3.40153a2.97928,2.97928,0,0,0-2.999,0L.50505,10.82893a1.00309,1.00309,0,1,0,.99966,1.73939l1.49947-.85969v12.2957a2.995,2.995,0,0,0,2.999,2.999H23.99691a2.99494,2.99494,0,0,0,2.99886-2.999V11.70863l1.49957.85969a1.00306,1.00306,0,1,0,.99953-1.73939Zm-4.49843,13.1754a1.0025,1.0025,0,0,1-.99953.99967H6.00314a1.00261,1.00261,0,0,1-.99966-.99967V10.569l9.49667-5.42812a.99318.99318,0,0,1,.99966,0L24.99644,10.569Z"/>',
  },
  xmark: {
    viewBox: "0 0 384 512",
    content:
      '<path fill="currentColor" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>',
  },
  clipboard: {
    viewBox: "0 0 50 50",
    content:
      '<path fill="currentColor" d="M32.14286,22.61905H23.21429a1.78572,1.78572,0,1,1,0-3.57143h8.92857a1.78572,1.78572,0,0,1,0,3.57143Z"/><path d="M32.14286,29.7619H23.21429a1.78571,1.78571,0,1,1,0-3.57142h8.92857a1.78571,1.78571,0,1,1,0,3.57142Z"/><path d="M32.14286,36.90476H23.21429a1.78572,1.78572,0,1,1,0-3.57143h8.92857a1.78572,1.78572,0,0,1,0,3.57143Z"/><path d="M17.87574,22.63765a1.795,1.795,0,0,1-.0186-3.59h.0186a1.795,1.795,0,0,1,0,3.59Z"/><path d="M17.87574,29.78051a1.795,1.795,0,0,1-.0186-3.59h.0186a1.795,1.795,0,0,1,0,3.59Z"/><path d="M17.87574,36.92336a1.795,1.795,0,0,1-.0186-3.59h.0186a1.795,1.795,0,0,1,0,3.59Z"/><path d="M37.5,50h-25a7.15068,7.15068,0,0,1-7.14286-7.14286V11.3258a6.97656,6.97656,0,0,1,6.11979-6.97545q1.76654-.204,3.53772-.35458A7.17446,7.17446,0,0,1,21.42857,0h7.14635a7.17275,7.17275,0,0,1,6.40927,3.99577q1.777.15,3.53771.35458a6.97745,6.97745,0,0,1,6.121,6.97545V42.85714A7.15068,7.15068,0,0,1,37.5,50ZM14.32059,7.64276q-1.21722.1151-2.4321.25577A3.40518,3.40518,0,0,0,8.92857,11.3258V42.85714A3.57534,3.57534,0,0,0,12.5,46.42857h25a3.57534,3.57534,0,0,0,3.57143-3.57143V11.3258a3.40606,3.40606,0,0,0-2.96108-3.42727q-1.212-.14126-2.43094-.25577a3.57727,3.57727,0,0,1-3.53655,3.07153H17.85714A3.57727,3.57727,0,0,1,14.32059,7.64276ZM17.9827,6.2a3.59114,3.59114,0,0,0-.12556.94285H32.14286a3.56735,3.56735,0,0,0-.1244-.93936L32.001,6.13839a3.5869,3.5869,0,0,0-3.42727-2.567H21.42857a3.58092,3.58092,0,0,0-3.42843,2.5658Z"/>',
  },
};

export default icons;
